import React from 'react';
import {
  MDBFooter,
  MDBContainer,
  MDBCol,
  MDBRow
} from 'mdb-react-ui-kit';
import Button from 'react-bootstrap/Button';
import "../style.css";
import logoPPal from "../images/logo-2023F.png";


export default function Footer() {

  return (
    <MDBFooter style={{ backgroundColor: '#000000', color:"white", width: "100%"}} className='text-center text-lg-left'>
      <MDBContainer fluid className='p-4'>
        <MDBRow>
          <MDBCol md=''>
            <br></br>
            <h5 className='text' style={{color:"white", marginBottom: "20px", fontSize: '25px'}}>¡Explora Precios, Elige la Experiencia y Saborea la Felicidad Corporativa!</h5>
            <img
                src={logoPPal}
                width="220px"
                height="75px"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
            <img src="https://firebasestorage.googleapis.com/v0/b/menumarket-b8993.appspot.com/o/logo_ruta.svg?alt=media&token=5cf61c6f-9b5a-4748-a902-85eba12a96fb" alt="Logo" width="180" height="65"/>
            <h5 className='text' style={{color:"white", marginBottom: "20px"}}>Ganador Capital Semilla 2023</h5>
          </MDBCol>

          <MDBCol md=''>
            <h5 className='text-uppercase'> Contacto</h5>
            <br></br>
            <p >
                <img src="https://firebasestorage.googleapis.com/v0/b/menumarket-b8993.appspot.com/o/phone.png?alt=media&token=15bb7845-89b8-41d5-8f81-31e5de8d8fa2" alt="Telefono" width="20" height="20"/>
               +57 (311) 7917370</p>
            <p ><img src="https://firebasestorage.googleapis.com/v0/b/menumarket-b8993.appspot.com/o/mail.png?alt=media&token=315c162d-c694-4284-880f-022aceb4b880" alt="Mail" width="25" height="25"/> atencionalcliente@menumarket.co</p>
            <p >
              <a href='https://www.instagram.com/menumarket.co/?hl=es'> 
                <img src="https://firebasestorage.googleapis.com/v0/b/menumarket-b8993.appspot.com/o/instagram-menumarket-icon.png?alt=media&token=6e73fa33-74da-4bd2-8100-c509d7d54eec" alt="Instagram" width="40" height="40"/>
              </a>
              <a href='https://www.linkedin.com/company/menumarket-co/'> 
                <img src="https://firebasestorage.googleapis.com/v0/b/menumarket-b8993.appspot.com/o/linkedin-menumarket-icon.png?alt=media&token=cd8c55c1-c047-424e-b958-fe1af00916cb" alt="LinkedIn" width="40" height="40"/>
              </a>
              <a href='https://www.facebook.com/profile.php?id=100084688107515'> 
                <img src="https://firebasestorage.googleapis.com/v0/b/menumarket-b8993.appspot.com/o/fb-menumarket-icon.png?alt=media&token=f1913e55-f40a-4f17-9adb-752435766db4" alt="Facebook" width="40" height="40"/>
              </a>
            </p>
            <h5 className='text-uppercase'>¡La diversión esta servida!</h5>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <div className='text-center p-3' style={{ backgroundColor: '#000000', color:"white"}}>
        &copy; {new Date().getFullYear()} Copyright:{' '}
        <a className='text-center p-3' href='https://menumarket.co/'>Menumarket.co</a>
      </div>
    </MDBFooter>
  );
}